/* eslint-disable react/prop-types */
import React, { useState, useContext } from 'react';
import {
    Navbar,
    NavbarBrand,
    NavbarToggler,
    Nav,
    NavItem,
    Collapse,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem
} from 'reactstrap';
import styled from 'styled-components';
import { isMobile, isBrowser } from 'react-device-detect';
import { Link, withRouter } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faBuilding } from '@fortawesome/free-solid-svg-icons';
import { APP_LOGO_URL, APP_NAME } from 'constants/app';
import firebase from 'config/firebase';
import { Box } from 'lib';
import { AppContext } from '../../AppContext';

const Logo = styled.img`
    height: 40px;
`;
const AppNameWrapper = styled.div`
    padding-left: 10px;
`;
const Title = styled.div`
    display: inline;
    font-weight: bold;
    color: #03496d;
`;

const TagLine = styled.div`
    font-size: 14px;
    margin-top: -7px;
`;

const NavbarStyled = styled(Navbar)`
    border-bottom: 1px solid #eee;
    position: fixed;
    width: 100%;
    z-index: 999;
`;

const DropdownItemStyled = styled(DropdownItem)`
    :active {
        background-color: #f7f7f7;
    }
`;

function Header(props) {
    const [state, setState] = useState({ isOpen: false });
    const [appState, dispatch] = useContext(AppContext);
    const { user } = appState;
    function logout() {
        const {
            history: { push }
        } = props;
        firebase
            .auth()
            .signOut()
            .then(
                () => {
                    dispatch({ type: 'USER_LOGOUT_SUCCESS', data: null });
                    // remove the `truckingData` property from storage
                    window.localStorage.removeItem('truckingData');
                    // redirect back to home
                    push('/');
                    window.location.reload();
                },
                () => {
                    dispatch({ type: 'USER_LOGOUT_ERROR', data: 'Unable to log you out' });
                    // redirect back to home
                    push('/');
                }
            );
    }

    function setDisplayName() {
        return 'My Account';
    }

    function toggle() {
        if (isMobile || isBrowser) {
            const { isOpen } = state;
            setState({
                isOpen: !isOpen
            });
        }
    }

    const { isOpen } = state;

    return (
        <NavbarStyled color="white" expand="md" light>
            <NavbarBrand href="/">
                <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                    <Logo src={APP_LOGO_URL} alt="343trucking.com classifieds" loading="lazy" decoding="async" />
                    <AppNameWrapper>
                        <Title>{APP_NAME}</Title>
                        <TagLine>Classifieds &amp; More</TagLine>
                    </AppNameWrapper>
                </div>
            </NavbarBrand>
            {/* <NavbarToggler onClick={toggle} /> */}
            {/* <Collapse isOpen={isOpen} navbar>
                <Nav className="ml-auto" navbar>
                    {user ? (
                        <>
                            <UncontrolledDropdown nav inNavbar>
                                <DropdownToggle nav caret>
                                    {setDisplayName()}
                                </DropdownToggle>
                                <DropdownMenu right>
                                    <DropdownItemStyled>
                                        <Link to="/admin/account" className="no-text-decoration" onClick={toggle}>
                                            <Box display="flex" alignItems="baseline">
                                                <FontAwesomeIcon
                                                    icon={faUser}
                                                    className="mr-2"
                                                />
                                                <div className="ml-2">My Profile</div>
                                            </Box>
                                        </Link>
                                    </DropdownItemStyled>
                                    <DropdownItem divider />
                                    <DropdownItemStyled onClick={logout}>
                                        Logout
                                    </DropdownItemStyled>
                                </DropdownMenu>
                            </UncontrolledDropdown>
                        </>
                    ) : (
                        <>
                            <NavItem>
                                <Link
                                    className="nav-link"
                                    to="/learn"
                                    onClick={toggle}
                                >
                                    <FontAwesomeIcon icon={faBuilding} /> List Your Business
                                </Link>
                            </NavItem>
                            <NavItem>
                                <Link className="nav-link" to="/auth/login" onClick={toggle}>
                                    Login
                                </Link>
                            </NavItem>
                        </>
                    )}
                </Nav>
            </Collapse> */}
        </NavbarStyled>
    );
}

export default withRouter(Header);
