import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Footer } from 'components';
import { Container,Jumbotron, Button, Row, Col } from 'reactstrap';
import { isMobile } from 'react-device-detect';
import { APP_LOGO_URL, APP_NAME, NUMBER_OF_FREE_MONTHS } from 'constants/app';
import queryString from 'query-string';
import get from 'lodash.get';
import { Typography } from 'lib';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import { Logo } from 'pages/Details/components';

const HeroText = styled.h1`
    font-family: AvenirLTStd-Black,tahoma,verdana,arial,sans-serif !important;
    font-size: 50px;
    font-weight: bold;
`;

const LeadText = styled.p`
    font-size: 20px;
    width: 70%;
    margin: auto;
    margin-bottom: 20px;

    @media(max-width: 990px) {
        width: 100%;
    }
`;

function ComingSoon(props) {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    function navigate() {
        const { history } = props;
        history.push('/contact');
    }

    function renderListYourBusiness() {
        return (
            <div style={{ height: isMobile ? 'auto' : 'calc(100vh - 140px)' }}>
                <Container>
                    <Jumbotron className="bg-transparent text-center mb-0">
                        <HeroText>Coming Soon.</HeroText>
                        <LeadText>
                            343trucking helps you find trucks for sale, trailers for sale, and trucking services near you.
                        </LeadText>
                        <LeadText>
                            <p>Get <strong>{NUMBER_OF_FREE_MONTHS} months of free advertising</strong> when we go live!</p>
                            No credit card required. No commitment. No hidden fees.
                            We will contact you before your free trial ends to see if you want to continue advertising with us.
                            If you do, we will send you an invoice for the next month.
                            If you don&apos;t, we will remove your listing from our website.
                            It&apos;s that simple.
                        </LeadText>
                        <button className="btn btn-dark pt-2 pb-2 pl-5 pr-5" onClick={() => props.history.push('/contact')}>
                            Notify Me When Live
                        </button>
                        <div className="text-muted fs-12 mt-3">* This offer is for a limited only.</div>
                    </Jumbotron>
                </Container>
            </div>
        );
    }

    function getPageContent() {
        return renderListYourBusiness();
    }

    return (
        <>
            {getPageContent()}
            <Footer />
        </>
    );
}

ComingSoon.propTypes = {
    history: PropTypes.object
};

ComingSoon.defaultProps = {
    history: { push: () => { } }
};

export default ComingSoon;
