import styled from 'styled-components';

const LogoWrapper = styled.div`
    width: 100px;
    margin-bottom: 5px;
`;

const Logo = styled.div`
    background-image: url(${({ src }) => src});
    width: ${({ width }) => width || '100px'};
    height: 50px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}`;

export {
    LogoWrapper,
    Logo
};
